import { DisaggregatedMetric, Metric } from "../services/types";
import populationSvg from '../assets/population.svg';
import povertySvg from '../assets/poverty.svg';
import limitedEngSvg from '../assets/limited-english.svg';
import collegeSvg from '../assets/college.svg';
import unemploymentSvg from '../assets/unemploymentrate.svg';
import homeownerSvg from '../assets/homeowner.svg';
import renterSvg from '../assets/renter.svg';
import medianHomeSvg from '../assets/median-home.svg';
import laborForceSvg from '../assets/laborforce.svg';
import commuteSvg from '../assets/commute.svg';
import internetSvg from '../assets/internet.svg';
import businessTypeSvg from '../assets/business-type.svg';
import raceSvg from '../assets/raceethnicity.svg';
import immigrationSvg from '../assets/immigration.svg';
import hhincomeSvg from '../assets/hhincome.svg';


// #region Organization Metric Mapping
const organizationMetrics: { [orgId: number]: any[] } =
{ 
    // Thurston
    12:
    [
        {
            name: "Demographic",
            ids: [383, 384, 385, 387, 397, 399],
            order: [383, 387, 385],
            charts: [
                {
                    name: "race",
                    id: 397
                },
                {
                    name: "racelat",
                    id: 398
                },
                {
                    name: "immigration",
                    id: 399
                }
            ]
        },
        {
            name: "Workforce",
            ids: [ 386, 388, 389, 390, 391, 392, 393, 395, 396, 400],
            order: [
                [393, 392, 395], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [388, 390, 391], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [389, 386, 396]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 400
                }
            ]
        },
        {
            name: "Economy",
            ids: [394],
            order: [394],
            charts: [
                {
                    name: "empind",
                    id: 394
                }
            ]
        }
    ],
    // Kitsap
    13: 
    [
        {
            name: "Demographic",
            ids: [401, 402, 403, 405, 412, 414],
            order: [401, 405, 403],
            charts: [
                {
                    name: "race",
                    id: 412
                },
                {
                    name: "racelat",
                    id: 413
                },
                {
                    name: "immigration",
                    id: 414
                }
            ]
        },
        {
            name: "Workforce",
            ids: [ 404, 406, 407, 408, 409, 410, 411, 417, 418, 415],
            order: [
                [411, 410, 417], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [406, 408, 409], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [407, 404, 418]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 415
                }
            ]
        },
        {
            name: "Economy",
            ids: [416],
            order: [416],
            charts: [
                {
                    name: "empind",
                    id: 416
                }
            ]
        }
    ],
    // Skagit
    14:
    [
        {
            name: "Demographic",
            ids: [419, 424, 425, 431, 433, 428],
            order: [419, 431, 425],
            charts: [
                {
                    name: "race",
                    id: 433
                },
                {
                    name: "racelat",
                    id: 427
                },
                {
                    name: "immigration",
                    id: 428
                }
            ]
        },
        {
            name: "Workforce",
            ids: [ 429, 423, 430, 420, 421, 422, 432, 434, 436, 426],
            order: [
                [432, 422, 434], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [423, 420, 421], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [430, 429, 436]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 426
                }
            ]
        },
        {
            name: "Economy",
            ids: [435],
            order: [435],
            charts: [
                {
                    name: "empind",
                    id: 435
                }
            ]
        }
    ],
    // Commerce WA - All ADOs
    15:
    [
        {
            name: "Demographic",
            ids: [437, 450, 441, 446, 448, 443],
            order: [437, 446, 441],
            charts: [
                {
                    name: "race",
                    id: 448
                },
                {
                    name: "racelat",
                    id: 442
                },
                {
                    name: "immigration",
                    id: 443
                }
            ]
        },
        {
            name: "Workforce",
            ids: [ 449, 444, 439, 451, 447, 452, 440, 453, 445, 438],
            order: [
                [440, 445, 453], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [444, 451, 447], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [439, 449, 452]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 438
                }
            ]
        },
        {
            name: "Economy",
            ids: [454],
            order: [454],
            charts: [
                {
                    name: "empind",
                    id: 454
                }
            ]
        }
    ],
    // Adams
    16:
    [
        {
            name: "Demographic",
            ids: [464, 466, 467, 468, 473, 475],
            order: [475, 467, 473],
            charts: [
                {
                    name: "race",
                    id: 466
                },
                {
                    name: "racelat",
                    id: 470
                },
                {
                    name: "immigration",
                    id: 468
                }
            ]
        },
        {
            name: "Workforce",
            ids: [461, 462, 463, 465, 469, 471, 472, 474, 476, 477],
            order: [
                [463, 471, 477], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [461, 472, 474], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [465, 469, 476]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 462
                }
            ]
        },
        {
            name: "Economy",
            ids: [478],
            order: [478],
            charts: [
                {
                    name: "empind",
                    id: 478
                }
            ]
        }
    ],
    // Chelan
    17:
    [
        {
            name: "Demographic",
            ids: [552, 555, 558, 563, 565, 567],
            order: [552, 567, 563],
            charts: [
                {
                    name: "race",
                    id: 565
                },
                {
                    name: "racelat",
                    id: 564
                },
                {
                    name: "immigration",
                    id: 555
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [553, 554, 556, 557, 559, 560, 561, 562], 
            order: [
                [561, 553], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [560, 562, 554], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [559, 556]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 557
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [617],
            order: [617],
            charts: [
                {
                    name: "empind",
                    id: 617
                }
            ]
        }
    ],
    // Clallam
    18:
    [
        {
            name: "Demographic",
            ids: [479, 480, 488, 493],
            order: [479, 493, 480],
            charts: [
                {
                    name: "race",
                    id: 488
                },
                {
                    name: "racelat",
                    id: 492
                },
                {
                    name: "immigration",
                    id: 495
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [481, 483, 484, 485, 487, 489, 490, 494], 
            order: [
                [489, 494], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [481, 490, 483], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [487, 484]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 485
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [491],
            order: [491],
            charts: [
                {
                    name: "empind",
                    id: 491
                }
            ]
        }
    ],
    // CREDC
    19:
    [
        {
            name: "Demographic",
            ids: [499, 505, 506, 508, 509, 510],
            order: [499, 508, 505],
            charts: [
                {
                    name: "race",
                    id: 510
                },
                {
                    name: "racelat",
                    id: 515
                },
                {
                    name: "immigration",
                    id: 509
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [498, 500, 502, 507, 511, 512, 513, 516], 
            order: [
                [500, 507], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [498, 512, 513], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [502, 511]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 516
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [501],
            order: [501],
            charts: [
                {
                    name: "empind",
                    id: 501
                }
            ]
        }
    ],
    // Cowlitz
    20:
    [
        {
            name: "Demographic",
            ids: [522, 523, 524, 526, 529, 535],
            order: [535, 524, 529],
            charts: [
                {
                    name: "race",
                    id: 522
                },
                {
                    name: "racelat",
                    id: 517
                },
                {
                    name: "immigration",
                    id: 526
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [518, 519, 520, 521, 528, 530, 531, 534], 
            order: [
                [518, 534], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [530, 521, 520], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [531, 528]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 519
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [525],
            order: [525],
            charts: [
                {
                    name: "empind",
                    id: 525
                }
            ]
        }
    ],
    // Lewis
    21:
    [
        {
            name: "Demographic",
            ids: [538, 540, 543, 544, 546, 548],
            order: [535, 524, 529],
            charts: [
                {
                    name: "race",
                    id: 543
                },
                {
                    name: "racelat",
                    id: 541
                },
                {
                    name: "immigration",
                    id: 546
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [536, 537, 539, 542, 545, 547, 549, 551], 
            order: [
                [545, 551], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [537, 542, 549], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [539, 547]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 536
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [619],
            order: [619],
            charts: [
                {
                    name: "empind",
                    id: 619
                }
            ]
        }
    ],
    // EASC
    22:
    [
        {
            name: "Demographic",
            ids: [569, 570, 571, 576, 577, 583],
            order: [576, 583, 577],
            charts: [
                {
                    name: "race",
                    id: 571
                },
                {
                    name: "racelat",
                    id: 573
                },
                {
                    name: "immigration",
                    id: 569
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [568, 572, 575, 578, 579, 580, 581, 582], 
            order: [
                [581, 582], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [572, 579, 578], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [580, 575]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 568
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [748],
            order: [748],
            charts: [
                {
                    name: "empind",
                    id: 748
                }
            ]
        }
    ],
    // Pierce
    24:
    [
        {
            name: "Demographic",
            ids: [584, 591, 592, 594, 595, 597],
            order: [584, 597, 595],
            charts: [
                {
                    name: "race",
                    id: 592
                },
                {
                    name: "racelat",
                    id: 585
                },
                {
                    name: "immigration",
                    id: 594
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [586, 587, 588, 589, 593, 596, 598, 599], 
            order: [
                [586, 598], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [588, 593, 587], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [599, 596]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 589
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [791],
            order: [791],
            charts: [
                {
                    name: "empind",
                    id: 791
                }
            ]
        }
    ],
    // Jefferson
    25:
    [
        {
            name: "Demographic",
            ids: [600, 601, 602, 606, 595, 597],
            order: [602, 614, 613],
            charts: [
                {
                    name: "race",
                    id: 600
                },
                {
                    name: "racelat",
                    id: 603
                },
                {
                    name: "immigration",
                    id: 606
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [604, 605, 607, 608, 609, 610, 611, 612], 
            order: [
                [609, 605], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [607, 611, 608], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [612, 604]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 610
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [995],
            order: [995],
            charts: [
                {
                    name: "empind",
                    id: 995
                }
            ]
        }
    ],
    // Mason
    26:
    [
        {
            name: "Demographic",
            ids: [686, 688, 826, 836, 935, 1026],
            order: [686, 1026, 688],
            charts: [
                {
                    name: "race",
                    id: 826
                },
                {
                    name: "racelat",
                    id: 721
                },
                {
                    name: "immigration",
                    id: 935
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [690, 788, 862, 887, 911, 923, 1011, 1054], 
            order: [
                [911, 788], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [887, 923, 1054], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [690, 862]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 1011
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [880],
            order: [880],
            charts: [
                {
                    name: "empind",
                    id: 880
                }
            ]
        }
    ],
    // Grant
    27:
    [
        {
            name: "Demographic",
            ids: [636, 648, 793, 889, 945, 1013],
            order: [636, 945, 648],
            charts: [
                {
                    name: "race",
                    id: 889
                },
                {
                    name: "racelat",
                    id: 987
                },
                {
                    name: "immigration",
                    id: 793
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [659, 789, 834, 866, 910, 920, 964, 976], 
            order: [
                [964, 659], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [910, 976, 834], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [920, 789]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 866
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [733],
            order: [733],
            charts: [
                {
                    name: "empind",
                    id: 733
                }
            ]
        }
    ],
    // Grays
    28:
    [
        {
            name: "Demographic",
            ids: [867, 883, 890, 904, 957, 1025],
            order: [883, 957, 904],
            charts: [
                {
                    name: "race",
                    id: 1025
                },
                {
                    name: "racelat",
                    id: 950
                },
                {
                    name: "immigration",
                    id: 867
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [653, 766, 807, 822, 828, 942, 989, 1029], 
            order: [
                [766, 807], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [653, 822, 828], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [942, 1029]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 989
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [694],
            order: [694],
            charts: [
                {
                    name: "empind",
                    id: 694
                }
            ]
        }
    ],
    // Spokane
    29:
    [
        {
            name: "Demographic",
            ids: [751, 827, 843, 885, 926, 940],
            order: [751, 843, 885],
            charts: [
                {
                    name: "race",
                    id: 827
                },
                {
                    name: "racelat",
                    id: 825
                },
                {
                    name: "immigration",
                    id: 926
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [701, 726, 742, 772, 895, 997, 993, 1050], 
            order: [
                [997, 701], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [895, 1050, 772], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [742, 726]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 993
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [700],
            order: [700],
            charts: [
                {
                    name: "empind",
                    id: 700
                }
            ]
        }
    ],
    // Island
    30:
    [
        {
            name: "Demographic",
            ids: [666, 710, 750, 812, 875, 1057],
            order: [710, 750, 666],
            charts: [
                {
                    name: "race",
                    id: 875
                },
                {
                    name: "racelat",
                    id: 953
                },
                {
                    name: "immigration",
                    id: 1057
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [632, 645, 738, 765, 844, 848, 929, 1021], 
            order: [
                [645, 1021], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [738, 844, 929], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [765, 632]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 848
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [891],
            order: [891],
            charts: [
                {
                    name: "empind",
                    id: 891
                }
            ]
        }
    ],
    // Kittitas
    32:
    [
        {
            name: "Demographic",
            ids: [641, 845, 884, 888, 913, 967],
            order: [913, 845, 967],
            charts: [
                {
                    name: "race",
                    id: 884
                },
                {
                    name: "racelat",
                    id: 933
                },
                {
                    name: "immigration",
                    id: 888
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [649, 670, 680, 809, 886, 938, 1008, 1048], 
            order: [
                [680, 670], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [1008, 809, 886], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [938, 1048]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 649
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [969],
            order: [969],
            charts: [
                {
                    name: "empind",
                    id: 969
                }
            ]
        }
    ],
    // Klickitat
    33:
    [
        {
            name: "Demographic",
            ids: [625, 674, 898, 909, 921, 1062],
            order: [921, 898, 1062],
            charts: [
                {
                    name: "race",
                    id: 625
                },
                {
                    name: "racelat",
                    id: 693
                },
                {
                    name: "immigration",
                    id: 674
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [669, 708, 717, 790, 914, 917, 979, 986], 
            order: [
                [669, 917], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [979, 708, 717], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [790, 986]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 914
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [857],
            order: [857],
            charts: [
                {
                    name: "empind",
                    id: 857
                }
            ]
        }
    ],
    // Lincoln
    34:
    [
        {
            name: "Demographic",
            ids: [709, 728, 737, 745, 833, 918],
            order: [918, 709, 728],
            charts: [
                {
                    name: "race",
                    id: 745
                },
                {
                    name: "racelat",
                    id: 663
                },
                {
                    name: "immigration",
                    id: 737
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [679, 803, 813, 860, 879, 906, 1030, 1040], 
            order: [
                [813, 1040], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [879, 906, 679], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [860, 803]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 1030
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [1022],
            order: [1022],
            charts: [
                {
                    name: "empind",
                    id: 1022
                }
            ]
        }
    ],
    // Pacific
    35:
    [
        {
            name: "Demographic",
            ids: [629, 637, 667, 675, 681, 1059],
            order: [629, 637, 681],
            charts: [
                {
                    name: "race",
                    id: 675
                },
                {
                    name: "racelat",
                    id: 983
                },
                {
                    name: "immigration",
                    id: 667
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [642, 797, 811, 819, 975, 988, 1037, 1049], 
            order: [
                [988, 811], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [797, 1049, 819], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [642, 975]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 1037
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [835],
            order: [835],
            charts: [
                {
                    name: "empind",
                    id: 835
                }
            ]
        }
    ],
    // Bellingham
    36:
    [
        {
            name: "Demographic",
            ids: [655, 713, 716, 725, 735, 757, 1023],
            order: [1023, 757, 735],
            charts: [
                {
                    name: "race",
                    id: 655
                },
                {
                    name: "racelat",
                    id: 725
                },
                {
                    name: "immigration",
                    id: 713
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [753, 758, 774, 907, 963, 984, 1019, 1033], 
            order: [
                [753, 774], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [1019, 758, 907], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [984, 1033]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 963
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [959],
            order: [959],
            charts: [
                {
                    name: "empind",
                    id: 959
                }
            ]
        }
    ],
    // Columbia
    37:
    [
        {
            name: "Demographic",
            ids: [729, 769, 817, 856, 912, 1042],
            order: [769, 912, 729],
            charts: [
                {
                    name: "race",
                    id: 817
                },
                {
                    name: "racelat",
                    id: 720
                },
                {
                    name: "immigration",
                    id: 856
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [651, 671, 676, 795, 801, 832, 943, 962], 
            order: [
                [671, 651], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [676, 801, 832], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [795, 943]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 962
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [946],
            order: [946],
            charts: [
                {
                    name: "empind",
                    id: 946
                }
            ]
        }
    ],
    // POEDC
    38:
    [
        {
            name: "Demographic",
            ids: [654, 746, 781, 824, 970, 994],
            order: [994, 781, 654],
            charts: [
                {
                    name: "race",
                    id: 746
                },
                {
                    name: "racelat",
                    id: 639
                },
                {
                    name: "immigration",
                    id: 824
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [661, 698, 821, 937, 980, 1018, 1028, 1038], 
            order: [
                [661, 821], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [1038, 980, 698], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [1028, 1018]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 937
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [996],
            order: [996],
            charts: [
                {
                    name: "empind",
                    id: 996
                }
            ]
        }
    ],
    // Walla
    39:
    [
        {
            name: "Demographic",
            ids: [722, 747, 854, 873, 928, 944],
            order: [928, 854, 722],
            charts: [
                {
                    name: "race",
                    id: 873
                },
                {
                    name: "racelat",
                    id: 623
                },
                {
                    name: "immigration",
                    id: 747
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [624, 706, 782, 800, 997, 1024, 1051, 1060], 
            order: [
                [706, 800], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [624, 1060, 1024], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [1051, 782]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 997
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [689],
            order: [689],
            charts: [
                {
                    name: "empind",
                    id: 689
                }
            ]
        }
    ],
    // Whitman
    40:
    [
        {
            name: "Demographic",
            ids: [627, 660, 665, 855, 1020, 1034],
            order: [627, 1020, 1034],
            charts: [
                {
                    name: "race",
                    id: 665
                },
                {
                    name: "racelat",
                    id: 754
                },
                {
                    name: "immigration",
                    id: 660
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [631, 684, 712, 865, 881, 939, 966, 1055], 
            order: [
                [712, 1055], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [684, 881, 966], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [865, 939]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 631
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [952],
            order: [952],
            charts: [
                {
                    name: "empind",
                    id: 952
                }
            ]
        }
    ],
    // San Juan
    41:
    [
        {
            name: "Demographic",
            ids: [715, 740, 796, 863, 992, 1000],
            order: [1000, 740, 715],
            charts: [
                {
                    name: "race",
                    id: 992
                },
                {
                    name: "racelat",
                    id: 1032
                },
                {
                    name: "immigration",
                    id: 863
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [658, 714, 756, 767, 903, 958, 1010, 1043], 
            order: [
                [958, 756], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [1043, 903, 658], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [767, 1010]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 714
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [905],
            order: [905],
            charts: [
                {
                    name: "empind",
                    id: 905
                }
            ]
        }
    ],
    // Chamber
    42:
    [
        {
            name: "Demographic",
            ids: [695, 711, 837, 839, 897, 1039],
            order: [837, 695, 711],
            charts: [
                {
                    name: "race",
                    id: 897
                },
                {
                    name: "racelat",
                    id: 699
                },
                {
                    name: "immigration",
                    id: 1039
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [622, 741, 915, 951, 991, 998, 1035, 1045], 
            order: [
                [915, 991], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [1035, 998, 951], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [622, 1045]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 741
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [954],
            order: [954],
            charts: [
                {
                    name: "empind",
                    id: 954
                }
            ]
        }
    ],
    // Skamania
    43:
    [
        {
            name: "Demographic",
            ids: [633, 749, 779, 842, 972, 1058],
            order: [1058, 972, 779],
            charts: [
                {
                    name: "race",
                    id: 842
                },
                {
                    name: "racelat",
                    id: 691
                },
                {
                    name: "immigration",
                    id: 749
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [705, 719, 731, 787, 861, 874, 974, 1017], 
            order: [
                [705, 974], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [731, 787, 861], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [874, 719]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 1017
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [810],
            order: [810],
            charts: [
                {
                    name: "empind",
                    id: 810
                }
            ]
        }
    ],
    // SEWEDA
    44:
    [
        {
            name: "Demographic",
            ids: [770, 794, 864, 871, 872, 1004],
            order: [794, 871, 1004],
            charts: [
                {
                    name: "race",
                    id: 770
                },
                {
                    name: "racelat",
                    id: 692
                },
                {
                    name: "immigration",
                    id: 864
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [662, 683, 697, 786, 868, 971, 1012, 1056], 
            order: [
                [971, 786], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [1012, 662, 697], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [683, 1056]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 868
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [646],
            order: [646],
            charts: [
                {
                    name: "empind",
                    id: 646
                }
            ]
        }
    ],
    // TEA
    45:
    [
        {
            name: "Demographic",
            ids: [644, 652, 678, 763, 882, 1044],
            order: [652, 1044, 644],
            charts: [
                {
                    name: "race",
                    id: 678
                },
                {
                    name: "racelat",
                    id: 919
                },
                {
                    name: "immigration",
                    id: 882
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [638, 647, 657, 768, 773, 815, 858, 941], 
            order: [
                [941, 768], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [638, 858, 815], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [657, 773]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 647
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [849],
            order: [849],
            charts: [
                {
                    name: "empind",
                    id: 849
                }
            ]
        }
    ],
    // Tri-City
    47:
    [
        {
            name: "Demographic",
            ids: [626, 732, 736, 761, 780, 830],
            order: [736, 732, 830],
            charts: [
                {
                    name: "race",
                    id: 780
                },
                {
                    name: "racelat",
                    id: 955
                },
                {
                    name: "immigration",
                    id: 761
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [656, 682, 707, 799, 930, 1015, 1041, 1063], 
            order: [
                [682, 1041], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [656, 1015, 1063], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [707, 799]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 930
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [973],
            order: [973],
            charts: [
                {
                    name: "empind",
                    id: 973
                }
            ]
        }
    ],
    // Tri County
    48:
    [
        {
            name: "Demographic",
            ids: [760, 777, 850, 859, 948, 1027],
            order: [777, 850, 948],
            charts: [
                {
                    name: "race",
                    id: 859
                },
                {
                    name: "racelat",
                    id: 878
                },
                {
                    name: "immigration",
                    id: 760
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [664, 668, 718, 894, 900, 927, 932, 1064], 
            order: [
                [668, 1064], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [927, 894, 900], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [932, 718]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 664
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [1066],
            order: [1066],
            charts: [
                {
                    name: "empind",
                    id: 1066
                }
            ]
        }
    ],
    // Wahkiakum
    49:
    [
        {
            name: "Demographic",
            ids: [640, 730, 820, 852, 896, 1053],
            order: [730, 820, 896],
            charts: [
                {
                    name: "race",
                    id: 640
                },
                {
                    name: "racelat",
                    id: 630
                },
                {
                    name: "immigration",
                    id: 852
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [734, 743, 771, 776, 838, 876, 916, 925], 
            order: [
                [734, 925], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [776, 743, 916], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [771, 838]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 876
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [798],
            order: [798],
            charts: [
                {
                    name: "empind",
                    id: 798
                }
            ]
        }
    ],
    // Yakima
    50:
    [
        {
            name: "Demographic",
            ids: [727, 823, 846, 892, 949, 1006],
            order: [1006, 949, 823],
            charts: [
                {
                    name: "race",
                    id: 846
                },
                {
                    name: "racelat",
                    id: 936
                },
                {
                    name: "immigration",
                    id: 727
                }
            ]
        },
        {
            name: "Workforce", // Missing Four-Year Grad Rate and Workers within 45 Minutes by Transit
            ids: [672, 752, 870, 956, 960, 990, 1014, 1016], 
            order: [
                [752, 870], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [1014, 960, 956], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [990, 1016]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 672
                }
            ]
        },
        {
            name: "Economy", // Missing economy metrics
            ids: [1031],
            order: [1031],
            charts: [
                {
                    name: "empind",
                    id: 1031
                }
            ]
        }
    ],
};


const svgMap: { [orgId: number]: { [key: number]: string }} =
{
    // Thurston
    12: {
        383: populationSvg,  // Population
        384: populationSvg,  // People of Color
        385: limitedEngSvg,  // Limited English Proficiency
        386: internetSvg,    // Households with Internet
        387: povertySvg,     // Households under 200% Poverty
        388: unemploymentSvg,// Unemployment Rate
        389: medianHomeSvg,  // Median Home Value
        390: homeownerSvg,   // Home Owners
        391: renterSvg,      // Renters
        392: collegeSvg,     // Individuals with College Degrees
        393: laborForceSvg,  // Labor Force
        394: businessTypeSvg, // Employment by Industry'
        395: collegeSvg,     // Four-Year Graduation Cohort Rate
        396: commuteSvg,     // Workers within 45 Minutes by Transit
        397: raceSvg,        // Race & Ethnicity
        399: immigrationSvg, // Top Countries of Immigration
        400: hhincomeSvg,    // Household Income  
        // add other indicator svg mappings below
    },
    // Kitsap
    13: {
        401: populationSvg,  // Population
        402: populationSvg,  // People of Color
        403: limitedEngSvg,  // Limited English Proficiency
        404: internetSvg,    // Households with Internet
        405: povertySvg,     // Households under 200% Poverty
        406: unemploymentSvg,// Unemployment Rate
        407: medianHomeSvg,  // Median Home Value
        408: homeownerSvg,   // Home Owners
        409: renterSvg,      // Renters
        410: collegeSvg,     // Individuals with College Degrees
        411: laborForceSvg,  // Labor Force
        416: businessTypeSvg, // Employment by Industry'
        417: collegeSvg,     // Four-Year Graduation Cohort Rate
        418: commuteSvg,     // Workers within 45 Minutes by Transit
        412: raceSvg,        // Race & Ethnicity
        414: immigrationSvg, // Top Countries of Immigration
        415: hhincomeSvg,    // Household Income  
        // add other indicator svg mappings below
    },
    // Skagit
    14: {
        419: populationSvg,  // Population
        424: populationSvg,  // People of Color
        425: limitedEngSvg,  // Limited English Proficiency
        429: internetSvg,    // Households with Internet
        431: povertySvg,     // Households under 200% Poverty
        423: unemploymentSvg,// Unemployment Rate
        430: medianHomeSvg,  // Median Home Value
        420: homeownerSvg,   // Home Owners
        421: renterSvg,      // Renters
        422: collegeSvg,     // Individuals with College Degrees
        432: laborForceSvg,  // Labor Force
        435: businessTypeSvg, // Employment by Industry'
        434: collegeSvg,     // Four-Year Graduation Cohort Rate
        436: commuteSvg,     // Workers within 45 Minutes by Transit
        433: raceSvg,        // Race & Ethnicity
        428: immigrationSvg, // Top Countries of Immigration
        426: hhincomeSvg,    // Household Income  
        // add other indicator svg mappings below
    },
    // Commerce All ADOs
    15: {
        437: populationSvg,  // Population
        450: populationSvg,  // People of Color
        441: limitedEngSvg,  // Limited English Proficiency
        449: internetSvg,    // Households with Internet
        446: povertySvg,     // Households under 200% Poverty
        444: unemploymentSvg,// Unemployment Rate
        439: medianHomeSvg,  // Median Home Value
        451: homeownerSvg,   // Home Owners
        447: renterSvg,      // Renters
        445: collegeSvg,     // Individuals with College Degrees
        440: laborForceSvg,  // Labor Force
        454: businessTypeSvg, // Employment by Industry'
        453: collegeSvg,     // Four-Year Graduation Cohort Rate
        452: commuteSvg,     // Workers within 45 Minutes by Transit
        448: raceSvg,        // Race & Ethnicity
        443: immigrationSvg, // Top Countries of Immigration
        438: hhincomeSvg,    // Household Income  
        // add other indicator svg mappings below
    },
    // Adams
    16: {
        475: populationSvg,  // Population
        464: populationSvg,  // People of Color
        473: limitedEngSvg,  // Limited English Proficiency
        469: internetSvg,    // Households with Internet
        467: povertySvg,     // Households under 200% Poverty
        461: unemploymentSvg,// Unemployment Rate
        465: medianHomeSvg,  // Median Home Value
        472: homeownerSvg,   // Home Owners
        474: renterSvg,      // Renters
        471: collegeSvg,     // Individuals with College Degrees
        463: laborForceSvg,  // Labor Force
        478: businessTypeSvg, // Employment by Industry'
        477: collegeSvg,     // Four-Year Graduation Cohort Rate
        476: commuteSvg,     // Workers within 45 Minutes by Transit
        466: raceSvg,        // Race & Ethnicity
        468: immigrationSvg, // Top Countries of Immigration
        462: hhincomeSvg,    // Household Income  
        // add other indicator svg mappings below
    },
    // Chelan
    17: {
        552: populationSvg,  // Population
        558: populationSvg,  // People of Color
        563: limitedEngSvg,  // Limited English Proficiency
        556: internetSvg,    // Households with Internet
        567: povertySvg,     // Households under 200% Poverty
        560: unemploymentSvg,// Unemployment Rate
        559: medianHomeSvg,  // Median Home Value
        562: homeownerSvg,   // Home Owners
        554: renterSvg,      // Renters
        553: collegeSvg,     // Individuals with College Degrees
        561: laborForceSvg,  // Labor Force
        617: businessTypeSvg, // Employment by Industry'
        // 477: collegeSvg,     // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,     // Workers within 45 Minutes by Transit
        565: raceSvg,        // Race & Ethnicity
        555: immigrationSvg, // Top Countries of Immigration
        557: hhincomeSvg,    // Household Income  
        // add other indicator svg mappings below
    },
    // Clallam
    18: {
        479: populationSvg,  // Population
        496: populationSvg,  // People of Color
        480: limitedEngSvg,  // Limited English Proficiency
        484: internetSvg,    // Households with Internet
        493: povertySvg,     // Households under 200% Poverty
        481: unemploymentSvg,// Unemployment Rate
        487: medianHomeSvg,  // Median Home Value
        490: homeownerSvg,   // Home Owners
        483: renterSvg,      // Renters
        494: collegeSvg,     // Individuals with College Degrees
        489: laborForceSvg,  // Labor Force
        491: businessTypeSvg, // Employment by Industry'
        // 477: collegeSvg,     // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,     // Workers within 45 Minutes by Transit
        488: raceSvg,        // Race & Ethnicity
        495: immigrationSvg, // Top Countries of Immigration
        485: hhincomeSvg,    // Household Income  
        // add other indicator svg mappings below
    },
    // CREDC
    19: {
        499: populationSvg,   // Population
        506: populationSvg,   // People of Color
        505: limitedEngSvg,   // Limited English Proficiency
        511: internetSvg,     // Households with Internet
        508: povertySvg,      // Households under 200% Poverty
        498: unemploymentSvg, // Unemployment Rate
        502: medianHomeSvg,   // Median Home Value
        512: homeownerSvg,    // Home Owners
        513: renterSvg,       // Renters
        507: collegeSvg,      // Individuals with College Degrees
        500: laborForceSvg,   // Labor Force
        510: raceSvg,         // Race & Ethnicity
        509: immigrationSvg,  // Top Countries of Immigration
        516: hhincomeSvg,     // Household Income  
        501: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Cowlitz
    20: {
        535: populationSvg,   // Population
        523: populationSvg,   // People of Color
        529: limitedEngSvg,   // Limited English Proficiency
        528: internetSvg,     // Households with Internet
        524: povertySvg,      // Households under 200% Poverty
        530: unemploymentSvg, // Unemployment Rate
        531: medianHomeSvg,   // Median Home Value
        521: homeownerSvg,    // Home Owners
        520: renterSvg,       // Renters
        534: collegeSvg,      // Individuals with College Degrees
        518: laborForceSvg,   // Labor Force
        522: raceSvg,         // Race & Ethnicity
        526: immigrationSvg,  // Top Countries of Immigration
        519: hhincomeSvg,     // Household Income
        525: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Lewis
    21: {
        548: populationSvg,   // Population
        540: populationSvg,   // People of Color
        538: limitedEngSvg,   // Limited English Proficiency
        547: internetSvg,     // Households with Internet
        544: povertySvg,      // Households under 200% Poverty
        537: unemploymentSvg, // Unemployment Rate
        539: medianHomeSvg,   // Median Home Value
        542: homeownerSvg,    // Home Owners
        549: renterSvg,       // Renters
        551: collegeSvg,      // Individuals with College Degrees
        545: laborForceSvg,   // Labor Force
        543: raceSvg,         // Race & Ethnicity
        546: immigrationSvg,  // Top Countries of Immigration
        536: hhincomeSvg,     // Household Income
        619: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // EASC
    22: {
        576: populationSvg,   // Population
        570: populationSvg,   // People of Color
        577: limitedEngSvg,   // Limited English Proficiency
        575: internetSvg,     // Households with Internet
        583: povertySvg,      // Households under 200% Poverty
        572: unemploymentSvg, // Unemployment Rate
        580: medianHomeSvg,   // Median Home Value
        579: homeownerSvg,    // Home Owners
        578: renterSvg,       // Renters
        582: collegeSvg,      // Individuals with College Degrees
        581: laborForceSvg,   // Labor Force
        571: raceSvg,         // Race & Ethnicity
        569: immigrationSvg,  // Top Countries of Immigration
        568: hhincomeSvg,     // Household Income
        748: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Pierce
    24: {
        584: populationSvg,   // Population
        591: populationSvg,   // People of Color
        595: limitedEngSvg,   // Limited English Proficiency
        596: internetSvg,     // Households with Internet
        597: povertySvg,      // Households under 200% Poverty
        588: unemploymentSvg, // Unemployment Rate
        599: medianHomeSvg,   // Median Home Value
        593: homeownerSvg,    // Home Owners
        587: renterSvg,       // Renters
        598: collegeSvg,      // Individuals with College Degrees
        586: laborForceSvg,   // Labor Force
        592: raceSvg,         // Race & Ethnicity
        594: immigrationSvg,  // Top Countries of Immigration
        589: hhincomeSvg,     // Household Income
        791: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Jefferson
    25: {
        602: populationSvg,   // Population
        601: populationSvg,   // People of Color
        613: limitedEngSvg,   // Limited English Proficiency
        604: internetSvg,     // Households with Internet
        614: povertySvg,      // Households under 200% Poverty
        607: unemploymentSvg, // Unemployment Rate
        612: medianHomeSvg,   // Median Home Value
        611: homeownerSvg,    // Home Owners
        608: renterSvg,       // Renters
        605: collegeSvg,      // Individuals with College Degrees
        609: laborForceSvg,   // Labor Force
        600: raceSvg,         // Race & Ethnicity
        606: immigrationSvg,  // Top Countries of Immigration
        610: hhincomeSvg,     // Household Income
        995: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Mason
    26: {
        686: populationSvg,   // Population
        836: populationSvg,   // People of Color
        688: limitedEngSvg,   // Limited English Proficiency
        862: internetSvg,     // Households with Internet
        1026: povertySvg,      // Households under 200% Poverty
        887: unemploymentSvg, // Unemployment Rate
        690: medianHomeSvg,   // Median Home Value
        923: homeownerSvg,    // Home Owners
        1054: renterSvg,       // Renters
        788: collegeSvg,      // Individuals with College Degrees
        911: laborForceSvg,   // Labor Force
        826: raceSvg,         // Race & Ethnicity
        935: immigrationSvg,  // Top Countries of Immigration
        1011: hhincomeSvg,     // Household Income
        880: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Grant
    27: {
        636: populationSvg,   // Population
        1013: populationSvg,   // People of Color
        648: limitedEngSvg,   // Limited English Proficiency
        789: internetSvg,     // Households with Internet
        945: povertySvg,      // Households under 200% Poverty
        910: unemploymentSvg, // Unemployment Rate
        920: medianHomeSvg,   // Median Home Value
        976: homeownerSvg,    // Home Owners
        834: renterSvg,       // Renters
        659: collegeSvg,      // Individuals with College Degrees
        964: laborForceSvg,   // Labor Force
        889: raceSvg,         // Race & Ethnicity
        793: immigrationSvg,  // Top Countries of Immigration
        866: hhincomeSvg,     // Household Income
        733: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Grays
    28: {
        883: populationSvg,   // Population
        890: populationSvg,   // People of Color
        904: limitedEngSvg,   // Limited English Proficiency
        1029: internetSvg,     // Households with Internet
        957: povertySvg,      // Households under 200% Poverty
        653: unemploymentSvg, // Unemployment Rate
        942: medianHomeSvg,   // Median Home Value
        822: homeownerSvg,    // Home Owners
        828: renterSvg,       // Renters
        807: collegeSvg,      // Individuals with College Degrees
        766: laborForceSvg,   // Labor Force
        1025: raceSvg,         // Race & Ethnicity
        867: immigrationSvg,  // Top Countries of Immigration
        989: hhincomeSvg,     // Household Income
        694: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Spokane
    29: {
        751: populationSvg,   // Population
        940: populationSvg,   // People of Color
        885: limitedEngSvg,   // Limited English Proficiency
        726: internetSvg,     // Households with Internet
        843: povertySvg,      // Households under 200% Poverty
        895: unemploymentSvg, // Unemployment Rate
        742: medianHomeSvg,   // Median Home Value
        1050: homeownerSvg,    // Home Owners
        772: renterSvg,       // Renters
        701: collegeSvg,      // Individuals with College Degrees
        977: laborForceSvg,   // Labor Force
        827: raceSvg,         // Race & Ethnicity
        926: immigrationSvg,  // Top Countries of Immigration
        993: hhincomeSvg,     // Household Income
        700: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Island
    30: {
        710: populationSvg,   // Population
        812: populationSvg,   // People of Color
        666: limitedEngSvg,   // Limited English Proficiency
        632: internetSvg,     // Households with Internet
        750: povertySvg,      // Households under 200% Poverty
        738: unemploymentSvg, // Unemployment Rate
        765: medianHomeSvg,   // Median Home Value
        844: homeownerSvg,    // Home Owners
        929: renterSvg,       // Renters
        1021: collegeSvg,      // Individuals with College Degrees
        645: laborForceSvg,   // Labor Force
        875: raceSvg,         // Race & Ethnicity
        1057: immigrationSvg,  // Top Countries of Immigration
        848: hhincomeSvg,     // Household Income
        891: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Kittitas
    32: {
        913: populationSvg,   // Population
        641: populationSvg,   // People of Color
        967: limitedEngSvg,   // Limited English Proficiency
        1048: internetSvg,     // Households with Internet
        845: povertySvg,      // Households under 200% Poverty
        1008: unemploymentSvg, // Unemployment Rate
        938: medianHomeSvg,   // Median Home Value
        809: homeownerSvg,    // Home Owners
        886: renterSvg,       // Renters
        670: collegeSvg,      // Individuals with College Degrees
        680: laborForceSvg,   // Labor Force
        884: raceSvg,         // Race & Ethnicity
        888: immigrationSvg,  // Top Countries of Immigration
        649: hhincomeSvg,     // Household Income
        969: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Klickitat
    33: {
        921: populationSvg,   // Population
        909: populationSvg,   // People of Color
        1062: limitedEngSvg,   // Limited English Proficiency
        986: internetSvg,     // Households with Internet
        898: povertySvg,      // Households under 200% Poverty
        979: unemploymentSvg, // Unemployment Rate
        790: medianHomeSvg,   // Median Home Value
        708: homeownerSvg,    // Home Owners
        717: renterSvg,       // Renters
        917: collegeSvg,      // Individuals with College Degrees
        669: laborForceSvg,   // Labor Force
        625: raceSvg,         // Race & Ethnicity
        674: immigrationSvg,  // Top Countries of Immigration
        914: hhincomeSvg,     // Household Income
        857: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Lincoln
    34: {
        918: populationSvg,   // Population
        833: populationSvg,   // People of Color
        728: limitedEngSvg,   // Limited English Proficiency
        986: internetSvg,     // Households with Internet
        709: povertySvg,      // Households under 200% Poverty
        879: unemploymentSvg, // Unemployment Rate
        860: medianHomeSvg,   // Median Home Value
        906: homeownerSvg,    // Home Owners
        679: renterSvg,       // Renters
        1040: collegeSvg,      // Individuals with College Degrees
        813: laborForceSvg,   // Labor Force
        745: raceSvg,         // Race & Ethnicity
        737: immigrationSvg,  // Top Countries of Immigration
        1030: hhincomeSvg,     // Household Income
        1022: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Pacific
    35: {
        629: populationSvg,   // Population
        1059: populationSvg,   // People of Color
        681: limitedEngSvg,   // Limited English Proficiency
        975: internetSvg,     // Households with Internet
        637: povertySvg,      // Households under 200% Poverty
        797: unemploymentSvg, // Unemployment Rate
        642: medianHomeSvg,   // Median Home Value
        1049: homeownerSvg,    // Home Owners
        819: renterSvg,       // Renters
        811: collegeSvg,      // Individuals with College Degrees
        988: laborForceSvg,   // Labor Force
        675: raceSvg,         // Race & Ethnicity
        667: immigrationSvg,  // Top Countries of Immigration
        1037: hhincomeSvg,     // Household Income
        835: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Bellingham
    36: {
        1023: populationSvg,   // Population
        716: populationSvg,   // People of Color
        735: limitedEngSvg,   // Limited English Proficiency
        1033: internetSvg,     // Households with Internet
        757: povertySvg,      // Households under 200% Poverty
        1019: unemploymentSvg, // Unemployment Rate
        984: medianHomeSvg,   // Median Home Value
        758: homeownerSvg,    // Home Owners
        907: renterSvg,       // Renters
        774: collegeSvg,      // Individuals with College Degrees
        753: laborForceSvg,   // Labor Force
        655: raceSvg,         // Race & Ethnicity
        713: immigrationSvg,  // Top Countries of Immigration
        963: hhincomeSvg,     // Household Income
        959: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Columbia
    37: {
        769: populationSvg,   // Population
        1042: populationSvg,   // People of Color
        729: limitedEngSvg,   // Limited English Proficiency
        943: internetSvg,     // Households with Internet
        912: povertySvg,      // Households under 200% Poverty
        676: unemploymentSvg, // Unemployment Rate
        795: medianHomeSvg,   // Median Home Value
        801: homeownerSvg,    // Home Owners
        832: renterSvg,       // Renters
        651: collegeSvg,      // Individuals with College Degrees
        671: laborForceSvg,   // Labor Force
        817: raceSvg,         // Race & Ethnicity
        856: immigrationSvg,  // Top Countries of Immigration
        962: hhincomeSvg,     // Household Income
        946: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // POEDC
    38: {
        994: populationSvg,   // Population
        970: populationSvg,   // People of Color
        654: limitedEngSvg,   // Limited English Proficiency
        1018: internetSvg,     // Households with Internet
        781: povertySvg,      // Households under 200% Poverty
        1038: unemploymentSvg, // Unemployment Rate
        1028: medianHomeSvg,   // Median Home Value
        980: homeownerSvg,    // Home Owners
        698: renterSvg,       // Renters
        821: collegeSvg,      // Individuals with College Degrees
        661: laborForceSvg,   // Labor Force
        746: raceSvg,         // Race & Ethnicity
        824: immigrationSvg,  // Top Countries of Immigration
        937: hhincomeSvg,     // Household Income
        996: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Walla
    39: {
        928: populationSvg,   // Population
        944: populationSvg,   // People of Color
        722: limitedEngSvg,   // Limited English Proficiency
        782: internetSvg,     // Households with Internet
        854: povertySvg,      // Households under 200% Poverty
        624: unemploymentSvg, // Unemployment Rate
        1051: medianHomeSvg,   // Median Home Value
        1060: homeownerSvg,    // Home Owners
        1024: renterSvg,       // Renters
        800: collegeSvg,      // Individuals with College Degrees
        706: laborForceSvg,   // Labor Force
        873: raceSvg,         // Race & Ethnicity
        747: immigrationSvg,  // Top Countries of Immigration
        997: hhincomeSvg,     // Household Income
        689: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Whitman
    40: {
        627: populationSvg,   // Population
        855: populationSvg,   // People of Color
        1034: limitedEngSvg,   // Limited English Proficiency
        939: internetSvg,     // Households with Internet
        1020: povertySvg,      // Households under 200% Poverty
        684: unemploymentSvg, // Unemployment Rate
        865: medianHomeSvg,   // Median Home Value
        881: homeownerSvg,    // Home Owners
        966: renterSvg,       // Renters
        1055: collegeSvg,      // Individuals with College Degrees
        712: laborForceSvg,   // Labor Force
        665: raceSvg,         // Race & Ethnicity
        660: immigrationSvg,  // Top Countries of Immigration
        631: hhincomeSvg,     // Household Income
        952: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // San Juan
    41: {
        1000: populationSvg,   // Population
        796: populationSvg,   // People of Color
        715: limitedEngSvg,   // Limited English Proficiency
        1010: internetSvg,     // Households with Internet
        740: povertySvg,      // Households under 200% Poverty
        1043: unemploymentSvg, // Unemployment Rate
        767: medianHomeSvg,   // Median Home Value
        903: homeownerSvg,    // Home Owners
        658: renterSvg,       // Renters
        756: collegeSvg,      // Individuals with College Degrees
        958: laborForceSvg,   // Labor Force
        992: raceSvg,         // Race & Ethnicity
        863: immigrationSvg,  // Top Countries of Immigration
        714: hhincomeSvg,     // Household Income
        905: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Chamber
    42: {
        837: populationSvg,   // Population
        839: populationSvg,   // People of Color
        711: limitedEngSvg,   // Limited English Proficiency
        1045: internetSvg,     // Households with Internet
        695: povertySvg,      // Households under 200% Poverty
        1035: unemploymentSvg, // Unemployment Rate
        622: medianHomeSvg,   // Median Home Value
        998: homeownerSvg,    // Home Owners
        951: renterSvg,       // Renters
        991: collegeSvg,      // Individuals with College Degrees
        915: laborForceSvg,   // Labor Force
        897: raceSvg,         // Race & Ethnicity
        1039: immigrationSvg,  // Top Countries of Immigration
        741: hhincomeSvg,     // Household Income
        954: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Skamania
    43: {
        1058: populationSvg,   // Population
        633: populationSvg,   // People of Color
        779: limitedEngSvg,   // Limited English Proficiency
        719: internetSvg,     // Households with Internet
        972: povertySvg,      // Households under 200% Poverty
        731: unemploymentSvg, // Unemployment Rate
        874: medianHomeSvg,   // Median Home Value
        787: homeownerSvg,    // Home Owners
        861: renterSvg,       // Renters
        974: collegeSvg,      // Individuals with College Degrees
        705: laborForceSvg,   // Labor Force
        842: raceSvg,         // Race & Ethnicity
        749: immigrationSvg,  // Top Countries of Immigration
        1017: hhincomeSvg,     // Household Income
        810: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // SEWEDA
    44: {
        794: populationSvg,   // Population
        872: populationSvg,   // People of Color
        1004: limitedEngSvg,   // Limited English Proficiency
        1056: internetSvg,     // Households with Internet
        871: povertySvg,      // Households under 200% Poverty
        1012: unemploymentSvg, // Unemployment Rate
        683: medianHomeSvg,   // Median Home Value
        662: homeownerSvg,    // Home Owners
        697: renterSvg,       // Renters
        786: collegeSvg,      // Individuals with College Degrees
        971: laborForceSvg,   // Labor Force
        770: raceSvg,         // Race & Ethnicity
        864: immigrationSvg,  // Top Countries of Immigration
        868: hhincomeSvg,     // Household Income
        646: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // TEA
    45: {
        652: populationSvg,   // Population
        763: populationSvg,   // People of Color
        644: limitedEngSvg,   // Limited English Proficiency
        773: internetSvg,     // Households with Internet
        1044: povertySvg,      // Households under 200% Poverty
        638: unemploymentSvg, // Unemployment Rate
        657: medianHomeSvg,   // Median Home Value
        858: homeownerSvg,    // Home Owners
        815: renterSvg,       // Renters
        768: collegeSvg,      // Individuals with College Degrees
        941: laborForceSvg,   // Labor Force
        678: raceSvg,         // Race & Ethnicity
        882: immigrationSvg,  // Top Countries of Immigration
        647: hhincomeSvg,     // Household Income
        849: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Tri-City
    47: {
        736: populationSvg,   // Population
        626: populationSvg,   // People of Color
        830: limitedEngSvg,   // Limited English Proficiency
        799: internetSvg,     // Households with Internet
        732: povertySvg,      // Households under 200% Poverty
        656: unemploymentSvg, // Unemployment Rate
        707: medianHomeSvg,   // Median Home Value
        1015: homeownerSvg,    // Home Owners
        1063: renterSvg,       // Renters
        1041: collegeSvg,      // Individuals with College Degrees
        682: laborForceSvg,   // Labor Force
        780: raceSvg,         // Race & Ethnicity
        761: immigrationSvg,  // Top Countries of Immigration
        930: hhincomeSvg,     // Household Income
        973: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Tri County
    48: {
        777: populationSvg,   // Population
        1027: populationSvg,   // People of Color
        948: limitedEngSvg,   // Limited English Proficiency
        718: internetSvg,     // Households with Internet
        850: povertySvg,      // Households under 200% Poverty
        927: unemploymentSvg, // Unemployment Rate
        932: medianHomeSvg,   // Median Home Value
        894: homeownerSvg,    // Home Owners
        900: renterSvg,       // Renters
        1064: collegeSvg,      // Individuals with College Degrees
        668: laborForceSvg,   // Labor Force
        859: raceSvg,         // Race & Ethnicity
        760: immigrationSvg,  // Top Countries of Immigration
        664: hhincomeSvg,     // Household Income
        1066: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Wahkiakum
    49: {
        730: populationSvg,   // Population
        1053: populationSvg,   // People of Color
        896: limitedEngSvg,   // Limited English Proficiency
        838: internetSvg,     // Households with Internet
        820: povertySvg,      // Households under 200% Poverty
        776: unemploymentSvg, // Unemployment Rate
        771: medianHomeSvg,   // Median Home Value
        743: homeownerSvg,    // Home Owners
        916: renterSvg,       // Renters
        925: collegeSvg,      // Individuals with College Degrees
        734: laborForceSvg,   // Labor Force
        640: raceSvg,         // Race & Ethnicity
        852: immigrationSvg,  // Top Countries of Immigration
        876: hhincomeSvg,     // Household Income
        798: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
    // Yakima
    50: {
        1006: populationSvg,   // Population
        892: populationSvg,   // People of Color
        823: limitedEngSvg,   // Limited English Proficiency
        1016: internetSvg,     // Households with Internet
        949: povertySvg,      // Households under 200% Poverty
        1014: unemploymentSvg, // Unemployment Rate
        990: medianHomeSvg,   // Median Home Value
        960: homeownerSvg,    // Home Owners
        956: renterSvg,       // Renters
        870: collegeSvg,      // Individuals with College Degrees
        752: laborForceSvg,   // Labor Force
        846: raceSvg,         // Race & Ethnicity
        727: immigrationSvg,  // Top Countries of Immigration
        672: hhincomeSvg,     // Household Income
        1031: businessTypeSvg, // Employment by Industry
        // 477: collegeSvg,      // Four-Year Graduation Cohort Rate
        // 476: commuteSvg,      // Workers within 45 Minutes by Transit
    },
};

// #endregion



// getMetricGroupIds - get the metric id values for a specific grouping 
const getMetricGroupIds = (name: string) => {

    let ids = [];
    
    if(organizationMetrics[orgId] != undefined){
        const group: any = organizationMetrics[orgId].find((x: { name: string; }) => x.name === name);

        if(group != undefined){
            ids = group.ids;
        }
    }

    return ids;
}



// Store our organization Id where we can access it later
export var orgId: number = 12;
export const setOrgId = (id: number) => {
    orgId = id;
}

// Store our overlapping county fips codes for BLS and TRS data queries
export var countyId: string[] = [];
export const setCountyIds = (id: string[]) => {
    countyId = id;
}
export const getCountyIds = () => {
    return countyId;
}



// getSvgMap - get our svg mapping for the given organization and metric id
export const getSvgMap = (id: number) => {
    let svg = "";
    if(svgMap[orgId] != undefined){
        svg = svgMap[orgId][id];
    }
    return svg;
}

// getMetricGroupOrderIds - get the ordering for a metric group
export const getMetricGroupOrderIds = (name: string) => {
    let orderIds = [];
    
    if(organizationMetrics[orgId] != undefined){
        const group: any = organizationMetrics[orgId].find((x: { name: string; }) => x.name === name);

        if(group != undefined){
            orderIds = group.order;
        }
    }

    return orderIds;
}

// getMetricGroupChartId - get a metric id given the group and chart key
export const getMetricGroupChartId = ( group: string, key: string) => {
    let id = -1;

    if(organizationMetrics[orgId] != undefined){
        const g = organizationMetrics[orgId].find((x: { name: string; }) => x.name === group);
        if(g != undefined){
            
            if(g.charts != undefined){

                const chart = g.charts.find((x: { name: string; }) => x.name === key);
                if(chart != undefined){
                    id = chart.id;
                }
            }
        }
    }

    return id;
}

// // getInteractionsLayerId - each organization will have its own interactions layer id
// // Updated to query by interaction tag to remove hard-coding
// export const getInteractionsLayerId = async () => {

//     var layerId = '';

//     const endpoint = `https://t47lzv8rn2.execute-api.us-east-1.amazonaws.com/staging/layers/search?organizationId=${orgId}&tags=ado_business_interactions`;
//     let searchLayerResponse = await fetch(endpoint, { method: 'GET' });
//     const layerDetails = await searchLayerResponse.json();
//     console.log("layer details: ", layerDetails);
//     layerId = layerDetails.data[0].layer_id;
//     console.log("orgId, layerId: ", orgId, layerId);


//     // Legacy - remove when reference no longer needed
//     // switch(orgId){
//     //     case 12: layerId = '25'; break;
//     //     case 13: layerId = '29'; break;
//     //     case 14: layerId = '30'; break;
//     //     default: layerId = '25';
//     // }

//     return layerId;
// };

export const categorizeData = (indicators: DisaggregatedMetric[]) => {

    const categories = {
        Demographic: getMetricGroupIds("Demographic"), // Metric IDs for Households under 200% Poverty, Population, Limited English Proficiency, People of Color
        Workforce: getMetricGroupIds("Workforce"), // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Graduation Cohort Rate, Unemployment Rate, Home Owners, Renters, Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
        Economy: getMetricGroupIds("Economy"), // Metric IDs for Employment by Industry
        Interactions: [] // Will be populated with other data
    };

    const categorizedData = {
        Demographic: [] as Metric[],
        Workforce: [] as Metric[],
        Economy: [] as Metric[],
        Interactions: [] as Metric[]
    };

    // data.forEach((item) => {
    //     if (categories.Demographic.includes(item.id)) {
    //         categorizedData.Demographic.push(item);
    //     } else if (categories.Workforce.includes(item.id)) {
    //         categorizedData.Workforce.push(item);
    //     }
    // });

    // Filter out metric 399 from disaggregatedData
    // const filteredData = disaggregatedData.filter(item => item.id !== 399);

    indicators.forEach((item) => {
        if (categories.Demographic.includes(item.id)) {
            categorizedData.Demographic.push(item);
        } else if (categories.Workforce.includes(item.id)) {
            categorizedData.Workforce.push(item);
        } else if (categories.Economy.includes(item.id)) {
            categorizedData.Economy.push(item);
        }
    });

    // Ensure that tract data for top countries of immigration are added (to create chart label)
    // if (tractData) {
    //     const requiredMetrics = [399, 414, 428, 443, 468, 555, 495, 509, 526, 546, 569, 594, 606, 935, 793, 867, 926, 1057, 888, 674, 737, 667, 713, 856, 824, 747, 660, 863, 1039, 749, 864, 882, 761, 760, 852, 727]; // List of metric IDs to check
    //     const metricsToAdd = requiredMetrics.map(id => tractData.find(item => item.id === id)).filter(metric => metric !== undefined) as typeof tractData;

    //     metricsToAdd.forEach(metric => {
    //         if (metric && !categorizedData.Demographic.some(item => item.id === metric.id)) {
    //             categorizedData.Demographic.push(metric);
    //         }
    //     });
    // }

    return categorizedData;
};

export const fetchMetricMetadata = async (id: number) => {
    if (!id) {
        console.log('No id provided to fetch metric metadata');
        return;
    }

    try {
        const response = await fetch(`${import.meta.env.VITE_DASHBOARD_BACKEND_URL}/api/getmetricmetadata`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const result = await response.json();
        return result;
    } catch (error: any) {
        console.error('Error fetching metric metadata:', error);
    }
};


