import XLSX, { WorkBook } from 'xlsx-js-style';
import { DisaggregatedMetric, GeoData, GeoDetails, GeoTag, MetricMetadata } from '../services/types';
import { orgId } from './dashboardUtils';
import { capitalizeWords, getRatio } from './helpers';

const currentDate = new Date().toISOString().split('T')[0];
const geoEncoding = 'delta';

const fetchIndicators = async (geoDetails: GeoDetails[], geoTag: GeoTag | null, metricCategories: string[]): Promise<DisaggregatedMetric[]> => {
    try {
        if (!orgId) {
            return [];
        }

        console.log('Fetching All Indicators...')
        // Create an array of fetch promises, one for each metricCategory
        const fetchPromises = metricCategories.map(category =>
            fetch(`${import.meta.env.VITE_DASHBOARD_BACKEND_URL}/api/getallindicatorsv2`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    orgId,
                    dashboardView: category,
                    geoDetails,
                    geoEncoding,
                    geoTag,
                    allowDefault: true
                }),
            }).then(response => {
                if (!response.ok) {
                    throw new Error(`Failed to fetch data for ${category}`);
                }
                return response.json();
            })
        );

        // Wait for all promises to resolve in parallel
        const results = await Promise.all(fetchPromises);

        // Flatten the data from all categories
        const combinedData: DisaggregatedMetric[] = results.flat();

        if (combinedData === undefined) {
            return [];
        }

        return combinedData;

    }  catch (error) {
        console.error('Error fetching Metadata:', error);
        return [];
    }
};

const fetchMetadata = async (orgId: number) => {
    try {
        const response = await fetch(`${import.meta.env.VITE_DASHBOARD_BACKEND_URL}/api/getmetadatabyorg`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ orgId }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();
        return result.data || [];
    } catch (error) {
        console.error('Error fetching Metadata:', error);
        return [];
    }
};

const fetchGeoData = async (geoDetails: GeoDetails[], orgId: number, geoTag: GeoTag | null) => {
    try {
        // Make a single API call
        const response = await fetch(`${import.meta.env.VITE_DASHBOARD_BACKEND_URL}/api/getgroupedgeodata`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                orgId,
                geoDetails,
                geoEncoding,
                geoTag,
                allowDefault: true
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to fetch geo data');
        }

        const data = await response.json();
        console.log('GeoData Results:', data);

        return data as any[];
    } catch (error) {
        console.error('Error fetching geo data:', error);
        return [];
    }
};

// removeStubs - Function that ensures that all cells, even empty ones, are preserved when the worksheet is generated
const removeStubs = (wb: WorkBook) => {
    Object.values(wb.Sheets).forEach(ws => {
      Object.values(ws).filter(v => v.t === 'z').forEach(v => Object.assign(v,{t:'s',v:''}));
    });
    return wb;
}
 
// Updated combineData function to accept a specific exclusion list
const combineDataWithExclusions = (summaryData: DisaggregatedMetric[], metadata: MetricMetadata[], exclusions: string[]) => {
    return summaryData
        .filter(item => !exclusions.includes(item.name)) // Filter out excluded metrics
        .map(item => {
            const metadataItem = metadata.find(meta => meta.metric_id_ext === item.id);
 
            // Determine if the type is percentage
            const isPercentage = metadataItem && metadataItem.versions[0].unit === 'percentage';
            const isRatio = metadataItem && metadataItem.versions[0].unit === 'ratio';

            let value;

            // Determine the unit and set the value accordingly
            if (isPercentage) {
                value = (item.versions[0].value * 100).toFixed(2) + '%';
            } else if (isRatio) {
                const n = item.versions[0].percent_calc?.n;
                const d = item.versions[0].percent_calc?.d;
                if (n && d) {
                    value = getRatio(n, d, 0.05);
                } else value = 'N/A';
            } else {
                value = item.versions[0].value;
            }
 
            return {
                category: item.category,
                name: item.name,
                value: value,
                n: item.versions[0].percent_calc?.n || null,
                d: item.versions[0].percent_calc?.d || null,
                unit: item.versions[0].unit,
                year: item.versions[0].year,
                level_of_detail: metadataItem ? metadataItem.versions[0].level_of_detail : '',
                definition: metadataItem ? metadataItem.definition : '',
                why_important: metadataItem ? metadataItem.versions[0].why_important : '',
                source: metadataItem ? metadataItem.versions[0].source : '',
                source_url: metadataItem ? metadataItem.versions[0].source_url : '',
            };
        })
        // Sort by category first, then by name within each category
        .sort((a, b) => {
            if (a.category < b.category) return -1;
            if (a.category > b.category) return 1;
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
};

// Updated mapDisaggregationData function to accept a specific exclusion list
const mapDisaggregationDataWithExclusions = (summaryData: DisaggregatedMetric[], exclusions: string[]) => {
    return summaryData
        .filter(item => !exclusions.includes(item.name)) // Filter out excluded metrics
        .flatMap(item => {
            const disaggregations = item.versions[0].disaggregations || []; // Handle cases where disaggregations might be empty

            // Determine if the type is percentage or ratio
            const isPercentage = item.versions[0].unit === 'percentage';
            const isRatio = item.versions[0].unit === 'ratio';

            return disaggregations.map(disagg => {
            
                let value;
            
                // Determine the unit and set the value accordingly for disagg
                if (isPercentage) {
                    value = (disagg.value * 100).toFixed(2) + '%';
                } else if (isRatio) {
                    const n = disagg.percent_calc?.n;
                    const d = disagg.percent_calc?.d;
                    if (n && d) {
                        value = getRatio(n, d, 0.05);
                    } else {
                        value = 'N/A';
                    }
                } else {
                    value = disagg.value;
                }
            
                return {
                    category: item.category,
                    name: item.name,
                    disagg_name: disagg.name,
                    value: value,
                    n: disagg.percent_calc?.n || null,
                    d: disagg.percent_calc?.d || null,
                    unit: item.versions[0].unit,
                };
            });
        })
        // Sort by category first, then by name within each category
        .sort((a, b) => {
            if (a.category < b.category) return -1;
            if (a.category > b.category) return 1;
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
};
 
// Updated mapDynamicGeoData function to use a single exclusion list for geographic sheets
const mapDynamicGeoDataWithExclusions = (geoData: GeoData[], geoLevel: string, exclusions: string[]) => {
    // Filter data based on the specified geographic level
    const filteredData = geoData.filter(item => item.geo_lod === geoLevel);
 
    // Build headers dynamically from the first item's indicators, excluding items in the exclusion list
    const dynamicHeaders = [{ label: `${geoLevel} ID`, key: 'geo_id' }];
 
    // If there is at least one item, extract its indicators as dynamic columns, applying exclusions
    if (filteredData.length > 0) {
        const firstItem = filteredData[0];
        Object.keys(firstItem.indicators)
            .filter(indicatorName => !exclusions.includes(indicatorName)) // Exclude metrics in the list
            .forEach(indicatorName => {
                dynamicHeaders.push({ label: indicatorName, key: indicatorName });
            });
    }
 
    // Map the data based on the dynamic headers
    const mappedData = filteredData.map(item => {
        // Create a row object starting with geo_id
        const row: Record<string, number | string> = {
            geo_id: item.geo_id,
        };
 
        // Populate the row object with indicator values dynamically, excluding unwanted metrics
        Object.keys(item.indicators)
            .filter(indicatorName => !exclusions.includes(indicatorName)) // Exclude metrics in the list
            .forEach((indicatorName: string) => {
                row[indicatorName] = item.indicators[indicatorName];
            });
 
        return row;
    });
 
    return { headers: dynamicHeaders, data: mappedData };
};

export const handleExcelDownload = async (geoDetails: GeoDetails[], geoTag: GeoTag | null, metricCategories: string[], selectedArea: string, summaryExclusionList: string[], disaggregationExclusionList: string[], geographyExclusionList: string[], geographicLevels: string[], introText: string) => {
    const [summaryData, metadata, geoData] = await Promise.all([fetchIndicators(geoDetails, geoTag, metricCategories), fetchMetadata(orgId), fetchGeoData(geoDetails, orgId, geoTag)]);

    const combinedData = combineDataWithExclusions(summaryData, metadata, summaryExclusionList);
    
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // ================== Create Summary Worksheet ==================

    // Create a new worksheet with no initial headers
    const summaryWorksheet = XLSX.utils.json_to_sheet([], { header: [] }); // Start with an empty sheet

    // Include title as first row
    XLSX.utils.sheet_add_aoa(summaryWorksheet, [['Summary Data and Metadata for Selected Area']], { origin: 'A1' });

    // Include headers as second row
    XLSX.utils.sheet_add_aoa(summaryWorksheet, [summaryHeaders.map(header => header.label)], { origin: 'A2' });

    // Now add the combined data starting from row 3 (A3)
    XLSX.utils.sheet_add_json(summaryWorksheet, combinedData, { header: [], skipHeader: true, origin: 'A3' });

    // Remove stubs so we can style empty cells
    removeStubs({
        SheetNames: ['Summary'],
        Sheets: { 'Summary': summaryWorksheet } 
    });

    // Safely check if '!ref' is available
    const ref = summaryWorksheet['!ref'];

    if (ref) {
        // Set up cell styles
        const range = XLSX.utils.decode_range(ref);

        // Define column widths
        summaryWorksheet['!cols'] = [
            { wch: 16 },
            { wch: 30 },
            { wch: 16 },
            { wch: 16 },
            { wch: 16 },
            { wch: 20 },
            { wch: 13 },
            { wch: 20 },
            { wch: 30 },
            { wch: 30 },
            { wch: 20 },
            { wch: 20 },
        ];

        // Ensure the range for merging and styling is defined
        const mergeRange = { s: { r: 0, c: 0 }, e: { r: 0, c: 11 } };

        // Add merge range to the worksheet
        if (!summaryWorksheet['!merges']) {
            summaryWorksheet['!merges'] = [];
        }
        summaryWorksheet['!merges'].push(mergeRange);

        styleWorksheetCells(range, summaryWorksheet, 'default');

        // Right align all cells in the "Value" column (column C or index 2), we need to do this because percentage values default to left alignment
        for (let row = 2; row <= range.e.r; row++) { // Start from row 2 (A3 in Excel, data starts from this row)
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: 2 }); // Column 2 (Value column)
            summaryWorksheet[cellAddress] = summaryWorksheet[cellAddress] || {};
            summaryWorksheet[cellAddress].s = {
                alignment: { horizontal: "right" } // Right-align the text
            };
        }
    }

    // Append the summary sheet to the workbook
    XLSX.utils.book_append_sheet(workbook, summaryWorksheet, 'Summary');


    // ================== Create Disaggregation Worksheet ==================

    // Create disaggregations data and sheet
    const disaggData = mapDisaggregationDataWithExclusions(summaryData, disaggregationExclusionList);

    // Create the new worksheet for disaggregations
    const disaggWorksheet = XLSX.utils.json_to_sheet([], { header: [] });

    // Add a title to the disaggregations worksheet
    XLSX.utils.sheet_add_aoa(disaggWorksheet, [['Summary Data with Disaggregations for Selected Area']], { origin: 'A1' });

    // Include headers as the second row in the disaggregations worksheet
    XLSX.utils.sheet_add_aoa(disaggWorksheet, [disaggHeaders.map(header => header.label)], { origin: 'A2' });

    // Add the disaggregations data starting from row 3
    XLSX.utils.sheet_add_json(disaggWorksheet, disaggData, { header: [], skipHeader: true, origin: 'A3' });

    // Remove stubs so we can style empty cells
    removeStubs({
        SheetNames: ['Disaggregations'],
        Sheets: { 'Disaggregations': disaggWorksheet } 
    });

    // Safely check if '!ref' is available
    const disaggRef = disaggWorksheet['!ref'];

    if (disaggRef) {
        // Merge and style the first row (title) for the disaggregations worksheet
        const disaggRange = XLSX.utils.decode_range(disaggRef);

        // Define column widths for the disaggregations worksheet
        disaggWorksheet['!cols'] = [
            { wch: 16 },
            { wch: 30 },
            { wch: 30 },
            { wch: 16 },
            { wch: 16 },
            { wch: 16 },
            { wch: 20 },
        ];

        
        const disaggMergeRange = { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } };

        if (!disaggWorksheet['!merges']) {
            disaggWorksheet['!merges'] = [];
        }
        disaggWorksheet['!merges'].push(disaggMergeRange);

        styleWorksheetCells(disaggRange, disaggWorksheet, 'default');

        // Right align all cells in the "Value" column (column D or index 3), we need to do this because percentage values default to left alignment
        for (let row = 2; row <= disaggRange.e.r; row++) { // Start from row 2 (A3 in Excel, data starts from this row)
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: 3 }); // Column 2 (Value column)
            disaggWorksheet[cellAddress] = disaggWorksheet[cellAddress] || {};
            disaggWorksheet[cellAddress].s = {
                alignment: { horizontal: "right" } // Right-align the text
            };
        }
    }

    // Append the disaggregations sheet to the workbook
    XLSX.utils.book_append_sheet(workbook, disaggWorksheet, 'Disaggregations');


    // ================== Create Dynamic Geo Worksheets with Unified Geography Exclusions ==================

    // Create dynamic sheets from geographicLevels for each geographic level with the shared geographyExclusionList 
    geographicLevels.forEach(level => {
        const { headers, data } = mapDynamicGeoDataWithExclusions(geoData, level, geographyExclusionList);
 
        // Create the new worksheet for the geographic level
        const worksheet = XLSX.utils.json_to_sheet([], { header: [] });
 
        // Include headers as the first row in the worksheet
        XLSX.utils.sheet_add_aoa(worksheet, [headers.map(header => header.label)], { origin: 'A1' });
 
        // Add the dynamic data starting from row 2
        XLSX.utils.sheet_add_json(worksheet, data, { header: [], skipHeader: true, origin: 'A2' });

        // Remove stubs so we can style empty cells
        removeStubs({
            SheetNames: [`${level.charAt(0).toUpperCase() + level.slice(1)} Data`],
            Sheets: { [`${level.charAt(0).toUpperCase() + level.slice(1)} Data`]: worksheet } 
        });
 
        // Define column widths dynamically based on headers
        worksheet['!cols'] = Array(headers.length).fill({ wch: 20 });

        // Safely check if '!ref' is available
        const ref = worksheet['!ref'];

        if (ref) {
            // Set up cell styles
            const range = XLSX.utils.decode_range(ref);
            styleWorksheetCells(range, worksheet, 'other');
        }
 
        // Append the dynamic sheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, `${level.charAt(0).toUpperCase() + level.slice(1)} Data`);
    });

    // ================== Create Intro Worksheet ==================

    // Create a new worksheet for the Intro page
    const introWorksheet = XLSX.utils.aoa_to_sheet([]);

    // Add the header in the first cell
    XLSX.utils.sheet_add_aoa(introWorksheet, [[introText]], { origin: 'A1' });
    introWorksheet['A1'].s = { font: { bold: true, sz: 16 } };

    XLSX.utils.sheet_add_aoa(introWorksheet, [['CAILive 2024']], { origin: 'A2' });

    // Get the current date
    const downloadDate = new Date();
    const formattedDate = `${downloadDate.getMonth() + 1}/${downloadDate.getDate()}/${downloadDate.getFullYear()}`;

    // Add text to A3 with the formatted date
    XLSX.utils.sheet_add_aoa(introWorksheet, [[`Downloaded ${formattedDate}`]], { origin: 'A3' });

    // Add text to A4 with the selected area
    XLSX.utils.sheet_add_aoa(introWorksheet, [[`${selectedArea ? selectedArea : ''}`]], { origin: 'A4' });

    // Add column headers "Summary" and "Description" at A6 and B6
    XLSX.utils.sheet_add_aoa(introWorksheet, [['Summary', 'Description']], { origin: 'A6' });
    introWorksheet['A6'].s = { font: { bold: true } };
    introWorksheet['B6'].s = { font: { bold: true } };

    // Define the names of the base sheets, add geogrpahic levels then add them from A7 to A11 with hyperlinks
    const baseSheetNames = ['Summary', 'Disaggregations'];

    // Dynamically generate sheet names
    const geographicSheetNames = geographicLevels.map(level => `${capitalizeWords(level)} Data`);
    const sheetNames = [...baseSheetNames, ...geographicSheetNames];

    const descriptions = [
        'Summary of each indicator for the selected area, with information about each indicator.',
        'Disaggregated summary of each indicator for the selected area.',
        'Data for each census tract in the selected area, for data that is collected at the census tract level.',
        'Data for each block group in the selected area, for data that is collected at the block group level.',
        'Data for each school district in the selected area, for data that is collected at the school district level.'
    ];
    // Make column A wider
    introWorksheet['!cols'] = [{ wch: 20 }];
    
    sheetNames.forEach((name, index) => {
        // Add sheet names with links to their respective sheets
        const cellAddress = `A${7 + index}`;
        introWorksheet[cellAddress] = {
            v: name,
            f: `HYPERLINK("#'${name}'!A1", "${name}")`,
            s: { font: { color: { rgb: "0000FF" }, underline: true } }
        };
        // Add description in the B column
        const descriptionCellAddress = `B${7 + index}`;
        introWorksheet[descriptionCellAddress] = { v: descriptions[index], t: 's' };
    });

    XLSX.utils.sheet_add_aoa(introWorksheet, [['Helpful Resources & Links']], { origin: 'A13' });
    introWorksheet['A13'].s = { font: { bold: true } };

    // Add "FIPS Code Documentation" with a hyperlink in A14
    XLSX.utils.sheet_add_aoa(introWorksheet, [['FIPS Code Documentation']], { origin: 'A14' });
    introWorksheet['A14'].l = { Target: 'https://transition.fcc.gov/oet/info/maps/census/fips/fips.txt', Tooltip: 'FIPS Code Documentation' };
    introWorksheet['A14'].s = { font: { color: { rgb: "0000FF" }, underline: true } };

    // Append the Intro sheet to the workbook
    XLSX.utils.book_append_sheet(workbook, introWorksheet, 'Intro');

    // Move Intro to the first position in the workbook
    workbook.SheetNames = ['Intro', ...workbook.SheetNames.filter(name => name !== 'Intro')];

    // Export the workbook
    XLSX.writeFile(workbook, `excel_export_${currentDate}.xlsx`);
};


// ================== Excel Headers ==================
const summaryHeaders = [
    { label: 'Category', key: 'category' },
    { label: 'Metric Name', key: 'name' },
    { label: 'Value', key: 'value' },
    { label: 'Numerator', key: 'n' },
    { label: 'Denominator', key: 'd' },
    { label: 'Number Type', key: 'unit' },
    { label: 'Year', key: 'year' },
    { label: 'Geographic Scope', key: 'level_of_detail' },
    { label: 'Description', key: 'definition' },
    { label: 'Importance', key: 'why_important' },
    { label: 'Source', key: 'source' },
    { label: 'Source URL', key: 'source_url' },
];

const disaggHeaders = [
    { label: 'Category', key: 'category' },
    { label: 'Metric', key: 'name' },
    { label: 'Dissaggregation', key: 'disagg_name' },
    { label: 'Value', key: 'value' },
    { label: 'Numerator', key: 'n' },
    { label: 'Denominator', key: 'd' },
    { label: 'Number Type', key: 'unit' },
]

function styleWorksheetCells(range: XLSX.Range, worksheet: XLSX.WorkSheet, type: string) {
    if (type === 'default') {
        for (let row = range.s.r; row <= range.e.r; row++) {
            for (let col = range.s.c; col <= range.e.c; col++) {
                const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });

                // Set styles for the title row
                if (row === 0) {
                    worksheet[cellAddress] = worksheet[cellAddress] || {};
                    worksheet[cellAddress].s = {
                        fill: { fgColor: { rgb: "e2efda" } }, 
                        font: { bold: true, color: { rgb: "000000" } }
                    };
                } else if (row === 1) {
                    // Set styles for the header row
                    worksheet[cellAddress] = worksheet[cellAddress] || {};
                    worksheet[cellAddress].s = { 
                        font: { bold: true, color: { rgb: "000000" } }
                    };
                }
            }
        }
    } else {
        for (let row = range.s.r; row <= range.e.r; row++) {
            for (let col = range.s.c; col <= range.e.c; col++) {
                const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
    
                worksheet[cellAddress] = worksheet[cellAddress] || {};

                // Set styles for the header row
                if (row === 0) {
                    worksheet[cellAddress].s = { 
                        font: { bold: true, color: { rgb: "000000" } }
                    };
                } else {
                    // Set styles for alternate rows
                    if (row % 2 !== 0) {
                        worksheet[cellAddress].s = {
                            fill: { fgColor: { rgb: "D9D9D9" } },
                            alignment: { horizontal: "right" }
                        };
                    } else {
                        worksheet[cellAddress].s = {
                            alignment: { horizontal: "right" }
                        };
                    }
                }
            }
        }
    }    
}

// // Execute the downloadable
// handleExcelDownload();