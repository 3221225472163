import trashIcon from '../assets/trashcan.svg';

const ClearMapSelectionModal = () => {
    const commerceOrigin = "*";

    // handleClickIndexIcon - Handle click event for sending message to map to clear active selection area
    const handleClearMapSelection = async () => {
        // Send message to the parent window
        window.parent.postMessage(
            {
                message: "clearMapSelection",
            },
            commerceOrigin
        );
        console.log("Clear map selection message sent.");
    };
    
    return (
    <div className="fixed top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/3 bg-white border border-anti-flash rounded-md shadow-lg p-6 w-80 h-72 flex flex-col justify-start">
        <p className="text-gotham-gray font-bold text-sm mt-8">The selected area does not contain any available data. Please clear your selection below and choose a different area that includes data.</p>
        <button className="my-4 bg-petrol border border-petrol text-white py-1 rounded-md flex items-center justify-center h-10" onClick={handleClearMapSelection}>
            <img src={trashIcon} className='w-4 h-4 mr-2' alt="Trash can" />
            <p className="text-xs font-bold">Clear Map Selection</p>
        </button>
    </div>
    );
}

export default ClearMapSelectionModal;