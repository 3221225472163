import { MetricPanelProps } from "../../services/types";
import { getMetricGroupChartId, getMetricGroupOrderIds, getSvgMap } from "../../utils/dashboardUtils";
import MetricCard from "../MetricCard";
import DisaggregateMetricCard from "../DisaggregateMetricCard";
import ImmigrationChart from "../charts/ImmigrationChart";
import RaceEthnicityChart from "../charts/RaceEthnicityChart";

const DemographicMetrics = ({ indicators, categorizedData, tab }: MetricPanelProps) => {   
    const demoOrder = getMetricGroupOrderIds(tab);  // [383, 387, 385]; // Metric IDs for Population, Households under 200% Poverty, Limited English Proficiency

    const sortedDemoMetrics = categorizedData.Demographic
        .filter(metric => demoOrder.includes(metric.id)) // Filter the metrics
        .sort((a, b) => demoOrder.indexOf(a.id) - demoOrder.indexOf(b.id)); // Sort the metrics based on the defined order

    return (
        <div className="flex flex-wrap">
            <div className="flex w-full">
                {sortedDemoMetrics.map(metric => (
                    <MetricCard
                        key={metric.id}
                        id={metric.id}
                        title={metric.name}
                        value={metric.versions[0].value}
                        unit={metric.versions[0].unit}
                        svg={getSvgMap(metric.id)}
                    />
                ))}
            </div>
            <div className="flex w-full">
                {categorizedData.Demographic.filter(metric => 
                    [getMetricGroupChartId(tab, "race")].includes(metric.id) // Race & Ethnicity
                ).map(metric => (
                    <DisaggregateMetricCard
                        key={metric.id}
                        id={metric.id}
                        title={metric.name}
                        subText=""
                        svg={getSvgMap(metric.id)}
                    />
                ))}
            </div>
            <div className="w-full">
                <RaceEthnicityChart disaggregatedData={indicators}/>
            </div>
            <div className="flex w-full">
                {categorizedData.Demographic.filter(metric => 
                    [getMetricGroupChartId(tab, "immigration")].includes(metric.id) // Top Countries of Immigration
                ).map(metric => (
                    <DisaggregateMetricCard
                        key={metric.id}
                        id={metric.id}
                        title={metric.name}
                        subText=""
                        svg={getSvgMap(metric.id)}
                    />
                ))}
            </div>
            <div className="w-full ml-24">
                <ImmigrationChart disaggregatedData={indicators}/>
            </div>
        </div>
    );
}

export default DemographicMetrics;